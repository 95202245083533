import classNames from 'classnames'
import React, { useMemo } from 'react'
import { NSClass } from 'gga-types'
import { Tooltip } from '@mantine/core'
import { APP_LOCALE } from '@/constants/define'
import { GAME_INFO_MAPPING } from '@/constants/game'
import ImageComponent from '../ImageComponent'

const enServers = [
  {
    href: 'https://discord.gg/ndNxJAZHsY',
    image: '',
    title: 'Join GGA LOL Online Discord Server!',
    code: 'lol',
  },
  {
    href: 'https://discord.gg/ndNxJAZHsY',
    image: '',
    title: 'Join GGA Valorant Online Discord Server!',
    code: 'valorant',
  },
  // {
  //   href: "https://discord.gg/gga-eafc",
  //   image: "https://www.ggacademy.gg/wp-content/uploads/2023/10/fc-logo.png",
  //   title: "Join GGA FC Discord Server!",
  // },
].map(s => ({ ...s, image: GAME_INFO_MAPPING[s.code]?.icon || '' }))

const krServers = [
  {
    href: 'https://discord.gg/gga-lol',
    image: '',
    title: 'Join GGA LOL Online Discord Server!',
    code: 'lol',
  },
  {
    href: 'https://discord.gg/gga-ow',
    image: '',
    title: 'Join GGA OW Online Discord Server!',
    code: 'ow',
  },
  {
    href: 'https://discord.gg/gga-vlrt',
    image: '',
    title: 'Join GGA Valorant Online Discord Server!',
    code: 'valorant',
  },
  {
    href: 'https://discord.gg/gga-pubg',
    image: '',
    title: 'Join GGA PUBG Online Discord Server!',
    code: 'pubg',
  },
  {
    href: 'https://discord.gg/gga-eafc',
    image: '',
    title: 'Join GGA FC Discord Server!',
    code: 'fifa',
  },
].map(s => ({ ...s, image: GAME_INFO_MAPPING[s.code]?.icon || '' }))

const servers = APP_LOCALE === 'en_US' ? enServers : krServers

const DiscordServers = ({
  className,
  game,
}: {
  className?: string
  game?: NSClass.GameCategory
}) => {
  const displayServers = useMemo(() => {
    if (!game) {
      return servers
    } else {
      const displayServers = servers.filter(s => s.code === game.code)
      if (!displayServers.length) {
        return servers
      }
      return displayServers
    }
  }, [game])
  return (
    <div className={classNames('flex justify-around items-center', className)}>
      {displayServers.map((s, index) => {
        return (
          <Tooltip key={index} label={s.title}>
            <a href={s.href} target="_blank" rel="noreferrer">
              <ImageComponent className="w-8" src={s.image} />
            </a>
          </Tooltip>
        )
      })}
    </div>
  )
}

export default DiscordServers
