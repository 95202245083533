import { i18n } from 'next-i18next'

export const GAMELIST = () => [
  {
    key: 'lol',
    label: i18n?.t('lol'),
    disabled: false,
  },
  {
    key: 'overwatch',
    label: i18n?.t('overwatch'),
    disabled: false,
  },
  {
    key: 'pubg',
    label: i18n?.t('pubg'),
    disabled: true,
  },
  {
    key: 'valorant',
    label: i18n?.t('valorant'),
    disabled: true,
  },
]

export const OVERWATCH_SR_RANGE = [
  '< 2000',
  '2000 - 3000',
  '3000 - 4000',
  '> 4000',
]

export const OVERWATCH_POSITIONS = [
  'Tank',
  'DPS',
  'Support',
  'Off-Tank',
  'Off-Dps',
  'Off-Support',
]

export const LOL_POSITIONS = ['top', 'mid', 'jungler', 'adc', 'support']

export const LOL_TIERS = [
  'iron',
  'bronze',
  'silver',
  'gold',
  'platinum',
  'diamond',
  'master',
  'grandmaster',
  'challenger',
] as const

export const LOL_RANK_RANGE = ['I', 'II', 'III', 'IV'] as const
export const VALORANT_RANK_RANGE = ['I', 'II', 'III'] as const

export type ILOLTierGroupType = {
  tier: typeof LOL_TIERS[number]
  rank?: typeof VALORANT_RANK_RANGE[number]
}

export const LOL_NO_RANK_SET = new Set(['master', 'grandmaster', 'challenger'])
export const VALORANT_NO_RANK_SET = new Set(['radiant'])

export const LOL_TIER_GROUP = (() => {
  const result: ILOLTierGroupType[] = []

  LOL_TIERS.forEach(t => {
    if (LOL_NO_RANK_SET.has(t)) {
      result.push({
        tier: t,
      })
    } else {
      VALORANT_RANK_RANGE.forEach(r => {
        result.push({ tier: t, rank: r })
      })
    }
  })
  return result
})()

export const OW_TIERS = [
  'grandmaster',
  'master',
  'diamond',
  'platinum',
  'gold',
  'silver',
  'bronze',
].reverse()

export const VALORANT_TIERS = [
  'immortal',
  'diamond',
  'platinum',
  'gold',
  'silver',
  'bronze',
  'iron',
].reverse()

export const LOL_POSITION_ICON = {
  mid: '/images/lol/position/mid.png',
  top: '/images/lol/position/top.png',
  adc: '/images/lol/position/adc.png',
  support: '/images/lol/position/support.png',
  jungler: '/images/lol/position/jungler.png',
}

export const OW_POSITION_ICON = {
  tank: '/images/ow/positions/tank.png',
  dps: '/images/ow/positions/dps.png',
  support: '/images/ow/positions/support.png',
}

export const VALORANT_POSITION_ICON = {
  controller: '/images/valorant/positions/controller.png',
  duelist: '/images/valorant/positions/duelist.png',
  sentinel: '/images/valorant/positions/sentinel.png',
  initiator: '/images/valorant/positions/initiator.png',
}

// 如何生成？请参考根目录下的 get-lol-rank-percentage.js
export const LOL_RANK_PERCENTAGE = {
  'IRON IV': 98.21,
  'SILVER II': 58.6,
  'DIAMOND IV': 1.65,
  'IRON III': 98.09,
  'SILVER I': 49.5,
  'DIAMOND III': 0.94,
  'IRON II': 97.83,
  'GOLD IV': 42.3,
  'DIAMOND II': 0.63,
  'IRON I': 97.32,
  'GOLD III': 28.3,
  'DIAMOND I': 0.4,
  'BRONZE IV': 96.4,
  'GOLD II': 22,
  MASTER: 0.16,
  'BRONZE III': 93.2,
  'GOLD I': 16.8,
  GRANDMASTER: 0.035,
  'BRONZE II': 89.4,
  'PLATINUM IV': 13.5,
  CHALLENGER: 0.01,
  'BRONZE I': 83.9,
  'PLATINUM III': 7.2,
  'SILVER IV': 78,
  'PLATINUM II': 5,
  'SILVER III': 67,
  'PLATINUM I': 3.5,
}

export const OW_RANK_PERCENTAGE = {
  GRANDMASTER: 1,
  MASTER: 5,
  DIAMOND: 15,
  PLATINUM: 40,
  GOLD: 70,
  SILVER: 90,
  BRONZE: 99,
}

export const VALORANT_RANK_PERCENTAGE = {
  'IRON I': 100,
  'PLATINUM I': 11,
  'IRON II': 97.7,
  'PLATINUM II': 8.2,
  'IRON III': 94.6,
  'PLATINUM III': 6.2,
  'BRONZE I': 86.8,
  'DIAMOND I': 4.7,
  'BRONZE II': 78.7,
  'DIAMOND II': 3.1,
  'BRONZE III': 65.9,
  'DIAMOND III': 1.9,
  'SILVER I': 55.1,
  'IMMORTAL I': 0.7,
  'SILVER II': 42.1,
  'IMMORTAL II': 0.3,
  'SILVER III': 32.8,
  'IMMORTAL III': 0.1,
  'GOLD I': 25.2,
  RADIANT: 0.02,
  'GOLD II': 19.2,
  'GOLD III': 14.6,
}

export const LOL_TIER_ICON = {
  bronze: '/images/lol/bronze.png',
  silver: '/images/lol/silver.png',
  challenger: '/images/lol/challenger.png',
  diamond: '/images/lol/diamond.png',
  gold: '/images/lol/gold.png',
  grandmaster: '/images/lol/grandmaster.png',
  iron: '/images/lol/iron.png',
  master: '/images/lol/master.png',
  platinum: '/images/lol/platinum.png',
}

export const OW_TIER_ICON = {
  bronze: '/images/ow/tiers/bronze.png',
  silver: '/images/ow/tiers/silver.png',
  diamond: '/images/ow/tiers/diamond.png',
  gold: '/images/ow/tiers/gold.png',
  grandmaster: '/images/ow/tiers/grandmaster.png',
  iron: '/images/ow/tiers/iron.png',
  master: '/images/ow/tiers/master.png',
  platinum: '/images/ow/tiers/platinum.png',
}

export const VALORANT_TIER_ICON = {
  bronze: '/images/valorant/tiers/bronze.png',
  silver: '/images/valorant/tiers/silver.png',
  diamond: '/images/valorant/tiers/diamond.png',
  gold: '/images/valorant/tiers/gold.png',
  immortal: '/images/valorant/tiers/immortal.png',
  iron: '/images/valorant/tiers/iron.png',
  platinum: '/images/valorant/tiers/platinum.png',
  radiant: '/images/valorant/tiers/radiant.png',
}

/**
 * 使用game code找到相关信息
 */
export const GAME_INFO_MAPPING: Record<
  string,
  {
    icon: string
  }
> = {
  valorant: {
    icon: 'https://res.gengesports.cn/user-web/images/games/valorant_icon%402x.png',
  },
  lol: {
    icon: 'https://res.gengesports.cn/user-web/images/games/lol_icon%402x.png',
  },
  ow: {
    icon: 'https://www.ggacademy.gg/wp-content/themes/geng/images/ow_logo.png',
  },
  pubg: {
    icon: 'https://www.ggacademy.gg/wp-content/uploads/2021/11/pubg-logo.png',
  },
  fifa: {
    icon: 'https://res.gengesports.cn/user-web/images/games/fc_icon.png',
  },
  csgo: {
    icon: 'https://res.gengesports.cn/user-web/images/games/csgo2_icon.png',
  },
}
