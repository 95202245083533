import React from 'react'
import DiscordServers from '@/components/DiscordServers'
import ImageComponent from '@/components/ImageComponent'

const Discord = () => {
  return (
    <div className="fixed bottom-[6rem] z-100 right-6">
      <div className="relative flex flex-nowrap items-center justify-center font-black group">
        <ImageComponent
          src="https://www.ggacademy.gg/wp-content/uploads/2022/10/discord.png"
          width="48"
          height="48"
          className="cursor-pointer"
        />
        <div className="absolute right-0 text-black bottom-10 hidden group-hover:block">
          <section className="p-4 rounded bg-[#f7f7f7] w-64 mb-4">
            <div className="flex flex-nowrap justify-around items-center text-lg">
              <span className="flex items-center">
                <ImageComponent
                  className="h-4"
                  src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6918e57475a843f59f_icon_clyde_black_RGB.svg"
                />
              </span>
              <span>Join Our Discord</span>
            </div>
            <DiscordServers className={'mt-2'} />
          </section>
        </div>
      </div>
    </div>
  )
}

export default Discord
